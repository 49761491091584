import { Injectable } from '@angular/core';
import orderBy from 'lodash-es/orderBy';
export enum CountryCode {
  Canada = 'CAN',
  UnitedStatesOfAmerica = 'USA',
  Cameroon = 'CMR',
  UnitedArabEmirates = 'ARE',
  Pakistan = 'PAK',
  Philippines = 'PHL',
  Mexico = 'MEX',
  Morocco = 'MAR',
  SouthAfrica = 'ZAF',
  Turkey = 'TUR',
  India = 'IND',
}
export interface ICountry {
  code: CountryCode;
  label: string;
}

@Injectable({
  providedIn: 'root',
})
export class GeoService {
  public getCountries(): ICountry[] {
    return orderBy(
      [
        {
          code: CountryCode.Canada,
          label: 'Canada',
        },
        {
          code: CountryCode.UnitedStatesOfAmerica,
          label: 'United States of America',
        },
        {
          code: CountryCode.Cameroon,
          label: 'Cameroon',
        },
        {
          code: CountryCode.UnitedArabEmirates,
          label: 'United Arab Emirates',
        },
        {
          code: CountryCode.Pakistan,
          label: 'Pakistan',
        },
        {
          code: CountryCode.Philippines,
          label: 'Philippines',
        },
        {
          code: CountryCode.Mexico,
          label: 'Mexico',
        },
        {
          code: CountryCode.Morocco,
          label: 'Morocco',
        },
        {
          code: CountryCode.SouthAfrica,
          label: 'South Africa',
        },
        {
          code: CountryCode.Turkey,
          label: 'Turkey',
        },
        {
          code: CountryCode.India,
          label: 'India',
        },
      ],
      'label'
    );
  }

  public getRegionsByCountry(country: CountryCode): any {
    let regionsByCountry: any[] = [];
    switch (country) {
      case CountryCode.Canada:
        // eslint-disable-next-line no-case-declarations
        const canadianAreas: any[] = [
          { code: 'CA-AB', label: 'Alberta' },
          { code: 'CA-BC', label: 'British Columbia' },
          { code: 'CA-MB', label: 'Manitoba' },
          { code: 'CA-NB', label: 'New Brunswick' },
          { code: 'CA-NL', label: 'Newfoundland' },
          { code: 'CA-NT', label: 'Northwest Territories' },
          { code: 'CA-NS', label: 'Nova Scotia' },
          { code: 'CA-NU', label: 'Nunavut' },
          { code: 'CA-ON', label: 'Ontario' },
          { code: 'CA-PE', label: 'Prince Edward Island' },
          { code: 'CA-QC', label: 'Quebec' },
          { code: 'CA-SK', label: 'Saskatchewan' },
          { code: 'CA-YT', label: 'Yukon' },
        ];
        regionsByCountry = canadianAreas.map((area) => {
          const legacy: any = area;
          legacy.legacyCode = area.code.toLowerCase() + ',' + area.label.toLowerCase();
          return legacy;
        });
        break;
      case CountryCode.UnitedStatesOfAmerica:
        regionsByCountry = [
          { code: 'US-AK', label: 'Alaska' },
          { code: 'US-AL', label: 'Alabama' },
          { code: 'US-AR', label: 'Arkansas' },
          { code: 'US-AS', label: 'American Samoa' },
          { code: 'US-AZ', label: 'Arizona' },
          { code: 'US-CA', label: 'California' },
          { code: 'US-CO', label: 'Colorado' },
          { code: 'US-CT', label: 'Connecticut' },
          { code: 'US-DC', label: 'District of Columbia' },
          { code: 'US-DE', label: 'Delaware' },
          { code: 'US-FL', label: 'Florida' },
          { code: 'US-GA', label: 'Georgia' },
          { code: 'US-GU', label: 'Guam' },
          { code: 'US-HI', label: 'Hawaii' },
          { code: 'US-IA', label: 'Iowa' },
          { code: 'US-ID', label: 'Idaho' },
          { code: 'US-IL', label: 'Illinois' },
          { code: 'US-IN', label: 'Indiana' },
          { code: 'US-KS', label: 'Kansas' },
          { code: 'US-KY', label: 'Kentucky' },
          { code: 'US-LA', label: 'Louisiana' },
          { code: 'US-MA', label: 'Massachusetts' },
          { code: 'US-MD', label: 'Maryland' },
          { code: 'US-ME', label: 'Maine' },
          { code: 'US-MI', label: 'Michigan' },
          { code: 'US-MN', label: 'Minnesota' },
          { code: 'US-MO', label: 'Missouri' },
          { code: 'US-MS', label: 'Mississippi' },
          { code: 'US-MT', label: 'Montana' },
          { code: 'US-NC', label: 'North Carolina' },
          { code: 'US-ND', label: 'North Dakota' },
          { code: 'US-NE', label: 'Nebraska' },
          { code: 'US-NH', label: 'New Hampshire' },
          { code: 'US-NJ', label: 'New Jersey' },
          { code: 'US-NM', label: 'New Mexico' },
          { code: 'US-NV', label: 'Nevada' },
          { code: 'US-NY', label: 'New York' },
          { code: 'US-OH', label: 'Ohio' },
          { code: 'US-OK', label: 'Oklahoma' },
          { code: 'US-OR', label: 'Oregon' },
          { code: 'US-PA', label: 'Pennsylvania' },
          { code: 'US-PR', label: 'Puerto Rico' },
          { code: 'US-RI', label: 'Rhode Island' },
          { code: 'US-SC', label: 'South Carolina' },
          { code: 'US-SD', label: 'South Dakota' },
          { code: 'US-TN', label: 'Tennessee' },
          { code: 'US-TX', label: 'Texas' },
          { code: 'US-UT', label: 'Utah' },
          { code: 'US-VA', label: 'Virginia' },
          { code: 'US-VI', label: 'Virgin Islands' },
          { code: 'US-VT', label: 'Vermont' },
          { code: 'US-WA', label: 'Washington' },
          { code: 'US-WI', label: 'Wisconsin' },
          { code: 'US-WV', label: 'West Virginia' },
          { code: 'US-WY', label: 'Wyoming' },
        ];
        break;
      case CountryCode.Cameroon:
        regionsByCountry = [
          { code: 'CM-AD', label: 'Adamaoua' },
          { code: 'CM-CE', label: 'Centre' },
          { code: 'CM-ES', label: 'East' },
          { code: 'CM-EN', label: 'Far North' },
          { code: 'CM-LT', label: 'Littoral' },
          { code: 'CM-NO', label: 'North' },
          { code: 'CM-NW', label: 'North-West' },
          { code: 'CM-SU', label: 'South' },
          { code: 'CM-SW', label: 'South-West' },
          { code: 'CM-OU', label: 'West' },
        ];
        break;
      case CountryCode.Philippines:
        regionsByCountry = [
          { code: 'PH-14', label: 'Autonomous Region in Muslim Mindanao' },
          { code: 'PH-05', label: 'Bicol' },
          { code: 'PH-02', label: 'Cagayan Valley' },
          { code: 'PH-40', label: 'Calabarzon' },
          { code: 'PH-13', label: 'Caraga' },
          { code: 'PH-03', label: 'Central Luzon' },
          { code: 'PH-07', label: 'Central Visayas' },
          { code: 'PH-15', label: 'Cordillera Administrative Region' },
          { code: 'PH-11', label: 'Davao' },
          { code: 'PH-08', label: 'Eastern Visayas' },
          { code: 'PH-01', label: 'Ilocos' },
          { code: 'PH-41', label: 'Mimaropa' },
          { code: 'PH-00', label: 'National Capital Region' },
          { code: 'PH-10', label: 'Northern Mindanao' },
          { code: 'PH-12', label: 'Soccsksargen' },
          { code: 'PH-06', label: 'Western Visayas' },
          { code: 'PH-09', label: 'Zamboanga Peninsula' },
        ];
        break;
      case CountryCode.Pakistan:
        regionsByCountry = [
          { code: 'PK-IS', label: 'Islamabad' },
          { code: 'PK-BA', label: 'Balochistan' },
          { code: 'PK-KP', label: 'Khyber Pakhtunkhwa' },
          { code: 'PK-PB', label: 'Punjab' },
          { code: 'PK-SD', label: 'Sindh' },
          { code: 'PK-JK', label: 'Azad Jammu and Kashmir' },
          { code: 'PK-GB', label: 'Gilgit-Baltistan' },
        ];
        break;
      case CountryCode.UnitedArabEmirates:
        regionsByCountry = [
          { code: 'AE-AJ', label: 'Ajman' },
          { code: 'AE-AZ', label: 'Abu Dhabi' },
          { code: 'AE-FU', label: 'Fujairah' },
          { code: 'AE-SH', label: 'Sharjah' },
          { code: 'AE-DU', label: 'Dubai' },
          { code: 'AE-RK', label: 'Ras Al Khaimah' },
          { code: 'AE-UQ', label: 'Umm Al Quwain' },
        ];
        break;
      case CountryCode.Mexico:
        regionsByCountry = [
          { code: 'MX-CMX', label: 'Mexico City' },
          { code: 'MX-AGU', label: 'Aguascalientes' },
          { code: 'MX-BCN', label: 'Baja California' },
          { code: 'MX-BCS', label: 'Baja California Sur' },
          { code: 'MX-CAM', label: 'Campeche' },
          { code: 'MX-CHP', label: 'Chiapas' },
          { code: 'MX-CHH', label: 'Chihuahua' },
          { code: 'MX-COA', label: 'Coahuila de Zaragoza' },
          { code: 'MX-COL', label: 'Colima' },
          { code: 'MX-DUR', label: 'Durango' },
          { code: 'MX-GUA', label: 'Guanajuato' },
          { code: 'MX-GRO', label: 'Guerrero' },
          { code: 'MX-HID', label: 'Hidalgo' },
          { code: 'MX-MIC', label: 'Michoacán de Ocampo' },
          { code: 'MX-MOR', label: 'Morelos' },
          { code: 'MX-MEX', label: 'México' },
          { code: 'MX-NAY', label: 'Nayarit' },
          { code: 'MX-NLE', label: 'Nuevo León' },
          { code: 'MX-OAX', label: 'Oaxaca' },
          { code: 'MX-PUE', label: 'Puebla' },
          { code: 'MX-QUE', label: 'Querétaro' },
          { code: 'MX-ROO', label: 'Quintana Roo' },
          { code: 'MX-SLP', label: 'San Luis Potosí' },
          { code: 'MX-SIN', label: 'Sinaloa' },
          { code: 'MX-SON', label: 'Sonora' },
          { code: 'MX-TAB', label: 'Tabasco' },
          { code: 'MX-TAM', label: 'Tamaulipas' },
          { code: 'MX-TLA', label: 'Tlaxcala' },
          { code: 'MX-VER', label: 'Veracruz de Ignacio de la Llave' },
          { code: 'MX-YUC', label: 'Yucatán' },
          { code: 'MX-ZAC', label: 'Zacatecas' },
        ];
        break;
      case CountryCode.Morocco:
        regionsByCountry = [
          { code: 'MA-05', label: 'Béni Mellal-Khénifra' },
          { code: 'MA-06', label: 'Casablanca-Settat' },
          { code: 'MA-12', label: 'Dakhla-Oued Ed-Dahab' },
          { code: 'MA-08', label: 'Drâa-Tafilalet' },
          { code: 'MA-03', label: 'Fès-Meknès' },
          { code: 'MA-10', label: 'Guelmim-Oued Noun' },
          { code: 'MA-02', label: `L'Oriental` },
          { code: 'MA-11', label: 'Laâyoune-Sakia El Hamra' },
          { code: 'MA-07', label: 'Marrakech-Safi' },
          { code: 'MA-04', label: 'Rabat-Salé-Kénitra' },
          { code: 'MA-09', label: 'Souss-Massa' },
          { code: 'MA-01', label: 'Tanger-Tétouan-Al Hoceïma' },
        ];
        break;
      case CountryCode.SouthAfrica:
        regionsByCountry = [
          { code: 'ZA-EС', label: 'Eastern Cape' },
          { code: 'ZA-FS', label: 'Free State' },
          { code: 'ZA-GP', label: 'Gauteng' },
          { code: 'ZA-KZN', label: 'Kwazulu-Natal' },
          { code: 'ZA-LP', label: 'Limpopo' },
          { code: 'ZA-MP', label: 'Mpumalanga' },
          { code: 'ZA-NW', label: 'North-West' },
          { code: 'ZA-NC', label: 'Northern Cape' },
          { code: 'ZA-WC', label: 'Western Cape' },
        ];
        break;

      case CountryCode.Turkey:
        regionsByCountry = [
          { code: 'TR-01', label: 'Adana' },
          { code: 'TR-02', label: 'Adıyaman' },
          { code: 'TR-03', label: 'Afyonkara' },
          { code: 'TR-04', label: 'Ağrı' },
          { code: 'TR-68', label: 'Aksaray' },
          { code: 'TR-05', label: 'Amasya' },
          { code: 'TR-06', label: 'Ankara' },
          { code: 'TR-07', label: 'Antalya' },
          { code: 'TR-75', label: 'Ardahan' },
          { code: 'TR-08', label: 'Artvin' },
          { code: 'TR-09', label: 'Aydın' },
          { code: 'TR-10', label: 'Balıkesir' },
          { code: 'TR-74', label: 'Bartın' },
          { code: 'TR-72', label: 'Batman' },
          { code: 'TR-69', label: 'Bayburt' },
          { code: 'TR-11', label: 'Bilecik' },
          { code: 'TR-12', label: 'Bingöl' },
          { code: 'TR-13', label: 'Bitlis' },
          { code: 'TR-14', label: 'Bolu' },
          { code: 'TR-15', label: 'Burdur' },
          { code: 'TR-16', label: 'Bursa' },
          { code: 'TR-17', label: 'Çanakkale' },
          { code: 'TR-18', label: 'Çankırı' },
          { code: 'TR-19', label: 'Çorum' },
          { code: 'TR-20', label: 'Denizli' },
          { code: 'TR-21', label: 'Diyarbakı' },
          { code: 'TR-81', label: 'Düzce' },
          { code: 'TR-22', label: 'Edirne' },
          { code: 'TR-23', label: 'Elazığ' },
          { code: 'TR-24', label: 'Erzincan' },
          { code: 'TR-25', label: 'Erzurum' },
          { code: 'TR-26', label: 'Eskişehir' },
          { code: 'TR-27', label: 'Gaziantep' },
          { code: 'TR-28', label: 'Giresun' },
          { code: 'TR-29', label: 'Gümüşhane' },
          { code: 'TR-30', label: 'Hakkâri' },
          { code: 'TR-31', label: 'Hatay' },
          { code: 'TR-76', label: 'Iğdır' },
          { code: 'TR-32', label: 'Isparta' },
          { code: 'TR-34', label: 'İstanbul' },
          { code: 'TR-35', label: 'İzmir' },
          { code: 'TR-46', label: 'Kahramanm' },
          { code: 'TR-78', label: 'Karabük' },
          { code: 'TR-70', label: 'Karaman' },
          { code: 'TR-36', label: 'Kars' },
          { code: 'TR-37', label: 'Kastamonu' },
          { code: 'TR-38', label: 'Kayseri' },
          { code: 'TR-71', label: 'Kırıkkale' },
          { code: 'TR-39', label: 'Kırklarel' },
          { code: 'TR-40', label: 'Kırşehir' },
          { code: 'TR-79', label: 'Kilis' },
          { code: 'TR-41', label: 'Kocaeli' },
          { code: 'TR-42', label: 'Konya' },
          { code: 'TR-43', label: 'Kütahya' },
          { code: 'TR-44', label: 'Malatya' },
          { code: 'TR-45', label: 'Manisa' },
          { code: 'TR-47', label: 'Mardin' },
          { code: 'TR-33', label: 'Mersin' },
          { code: 'TR-48', label: 'Muğla' },
          { code: 'TR-49', label: 'Muş' },
          { code: 'TR-50', label: 'Nevşehir' },
          { code: 'TR-51', label: 'Niğde' },
          { code: 'TR-52', label: 'Ordu' },
          { code: 'TR-80', label: 'Osmaniye' },
          { code: 'TR-53', label: 'Rize' },
          { code: 'TR-54', label: 'Sakarya' },
          { code: 'TR-55', label: 'Samsun' },
          { code: 'TR-56', label: 'Siirt' },
          { code: 'TR-57', label: 'Sinop' },
          { code: 'TR-58', label: 'Sivas' },
          { code: 'TR-63', label: 'Şanlıurfa' },
          { code: 'TR-73', label: 'Şırnak' },
          { code: 'TR-59', label: 'Tekirdağ' },
          { code: 'TR-60', label: 'Tokat' },
          { code: 'TR-61', label: 'Trabzon' },
          { code: 'TR-62', label: 'Tunceli' },
          { code: 'TR-64', label: 'Uşak' },
          { code: 'TR-65', label: 'Van' },
          { code: 'TR-77', label: 'Yalova' },
          { code: 'TR-66', label: 'Yozgat' },
          { code: 'TR-67', label: 'Zonguldak' },
        ];
        break;
      case CountryCode.India:
        regionsByCountry = [
          { code: 'IN-AN', label: 'Andaman and Nicobar Islands' },
          { code: 'IN-AP', label: 'Andhra Pradesh' },
          { code: 'IN-AR', label: 'Arunachal Pradesh' },
          { code: 'IN-AS', label: 'Assam' },
          { code: 'IN-BR', label: 'Bihar' },
          { code: 'IN-CH', label: 'Chandigarh' },
          { code: 'IN-CT', label: 'Chhattisgarh' },
          { code: 'IN-DD', label: 'Daman and Diu' },
          { code: 'IN-DL', label: 'Delhi' },
          { code: 'IN-DN', label: 'Dadra and Nagar Haveli' },
          { code: 'IN-GA', label: 'Goa' },
          { code: 'IN-GJ', label: 'Gujarat' },
          { code: 'IN-HP', label: 'Himachal Pradesh' },
          { code: 'IN-HR', label: 'Haryana' },
          { code: 'IN-JH', label: 'Jharkhand' },
          { code: 'IN-JK', label: 'Jammu and Kashmir' },
          { code: 'IN-KA', label: 'Karnataka' },
          { code: 'IN-KL', label: 'Kerala' },
          { code: 'IN-LA', label: 'Ladakh' },
          { code: 'IN-LD', label: 'Lakshadweep' },
          { code: 'IN-MH', label: 'Maharashtra' },
          { code: 'IN-ML', label: 'Meghalaya' },
          { code: 'IN-MN', label: 'Manipur' },
          { code: 'IN-MP', label: 'Madhya Pradesh' },
          { code: 'IN-MZ', label: 'Mizoram' },
          { code: 'IN-NL', label: 'Nagaland' },
          { code: 'IN-OR', label: 'Odisha' },
          { code: 'IN-PB', label: 'Punjab' },
          { code: 'IN-PY', label: 'Puducherry' },
          { code: 'IN-RJ', label: 'Rajasthan' },
          { code: 'IN-SK', label: 'Sikkim' },
          { code: 'IN-TG', label: 'Telangana' },
          { code: 'IN-TN', label: 'Tamil Nadu' },
          { code: 'IN-TR', label: 'Tripura' },
          { code: 'IN-UP', label: 'Uttar Pradesh' },
          { code: 'IN-UT', label: 'Uttarakhand' },
          { code: 'IN-WB', label: 'West Bengal' },
        ];
        break;
      default:
        throw new Error('Failed to retrieve states/provinces for: ' + country);
    }
    return orderBy(regionsByCountry, 'label');
  }
}
